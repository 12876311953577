nav{
    width: 100%;
    background: rgba(10, 10,50,0.5);
    color: #fff;
    padding: 4px 0;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 10;
    
}

.logo{
    width: 180px;
    height: 80px;
    
}


nav ul li{
    display: inline-block;
    list-style: none;
    margin: 5px 20px;
    font-size: 16px;
}

.menu-icon{
    display: none;
}


nav ul li a img{
    width: 40px;
}


.touch{
    display: none;
}



/* ---------------media query----------------- */


 @media (max-width: 1000px){
    .logo{
        width: 140px;
    }
    nav ul li{
        margin: 10px 15px;

    }
 }


 @media (max-width: 840px){
    nav{
        padding: 15px 0;
    }
    nav ul{
        position: fixed;
        top: 0;
        right: -200px;
        bottom: 0;
        background: rgba(0,0,0,0.5);
        z-index: -1;
        width: 200px;
        padding-top: 70px;
        transition: 0.5s;
    }

    .nav-btn{
        width: 130px;
    }

    nav ul li{
        display: block;
        margin: 25px 40px;
    }
    .menu-icon{
        display: block;
        width: 30px;
        cursor: pointer;
    }

    .touch{
        display: flex;
        margin: 0 -20px;;


        /* justify-content: space-between; */
    }

    .call img{
        width: 40px;

    }

    .hide-mobile-menu{
        right: -5px;
    }
 }



