.contact{
    margin: 80px auto;
    max-width: 90%;
    display: flex;
    align-items: center;
    justify-content:  space-between;

}

.contact-col{
    flex-basis: 48%;
    color: #676767;
}

.contact-col h3{
    color: #000f38;
    font-weight: 500;
    font-size: 25px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

}

.contact-col h3 img{
    width: 35px;
    margin-left: 10px;
}

.contact-col p {
    max-width: 450px;
    line-height: 1.7;
}

.contact-col ul li{
    display: flex;
    align-items: center;
    margin: 20px 0;
}

.contact-col ul li img{
    width: 25px;
    margin-right: 10px;
}

.contact form input{
    display: block;
    width: 100%;
    background: #0047ab;
    padding: 15px;
    border: 0;
    outline: 0;
    margin-bottom: 15px;
    margin-top: 5px;
    resize: none;
    color: #fff;
}

.contact-col span{
    display: block;
    margin: 20px  0 ;
    
}


select{
    display: flex;
    text-align: center;
    width: 100%;
    height: 40px;
    margin-bottom: 20px;
}

option{
    background-color: rgba(0,0,0,0.5);
}





/* ------------------media query---------------------- */



@media (max-width:700px) {

    .contact{
        display: block;

    }

    .contact-col{
        padding: 20px;
    }

   
    
}